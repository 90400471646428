import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Charitydata } from './charitydata.model'
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { charitydata } from './classes/charitydata';
import { toArray } from 'rxjs/operators';
import { charityfill } from './charityfill';
import { statefill } from './state'
import { addcharity } from './addcharity';
import { Router } from '@angular/router';


let httpoption = {
  headers: new HttpHeaders({
    'X-auth-token':  localStorage.getItem('token')    

  })
};

let httpoptions = {
  headers: new HttpHeaders({
    'X-auth-token':  localStorage.getItem('token'),
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GetcharityService {
  
  public det = [];
 
  constructor(private http: HttpClient, private router: Router) { }

  private charityurl = 'https://app.pluckyadmin.com/api/v1';

 
charitydata() : Observable<charitydata[]> {
  return this.http.get<charitydata[]>(this.charityurl+'/charities', httpoption);
    }


charityadd() : Observable<charityfill[]> {
      return this.http.get<charityfill[]>(this.charityurl+'/common/geo/country', httpoption);
    }

stateadd(country) : Observable<statefill[]> {
  return this.http.get<statefill[]>(this.charityurl+'/common/geo/state/'+ country , httpoption);
}

postcharity(charity : addcharity){
  var body = JSON.stringify(charity);

  return this.http.post<any>(this.charityurl + '/charities/onboard', body, httpoptions );
}

logoutUser() {
 localStorage.removeItem('token');
  this.router.navigate(['/login'])

}
     
}

