import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { GetcharityService } from './getcharity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  isLoggedIn$: Observable<boolean>;
  constructor(private authservice: AuthService, private getcharityservice: GetcharityService){}

logoutUser() {
  this.getcharityservice.logoutUser();


}

loggedIn() {
  this.authservice.loggedIn();
}

}
