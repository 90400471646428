import { Component, OnInit } from '@angular/core';
import { charityfill } from '../charityfill';
import { statefill } from '../state'
import { addcharity } from '../addcharity'
import { GetcharityService } from '../getcharity.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'add-charity',
  templateUrl: './add-charity.component.html',
  styleUrls: ['./add-charity.component.scss']
})
export class AddCharityComponent implements OnInit {

  countrydet : charityfill[];
  statedet : statefill[]; 
  charitydet : addcharity[];
  addcharity1 = new addcharity('','','','','','',0,0,0,'','','','','')
 
 
  constructor(private getcharity : GetcharityService, private router: Router) { }

  ngOnInit() {

    
    this.getcharity.charityadd()
    .subscribe((data ) => {
      console.log(data);
      this.countrydet = data
      console.log(this.countrydet);
    });

  }
 onChange(countryval){
console.log(countryval)
  this.getcharity.stateadd(countryval)
    .subscribe(( resp ) => {
      console.log(resp);
      this.statedet = resp
      console.log(this.statedet);
    });
 }

 resetForm(form? : NgForm){
   form.reset();

 }

 onSubmit(){


   this.getcharity.postcharity(this.addcharity1)
    .subscribe(data => console.log('success!',data),
   
    error => console.error('Error!',error)    
    );
    
    this.router.navigate(['/charity']);
 }
}
