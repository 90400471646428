import { Component, OnInit } from '@angular/core';
import { GetcharityService } from '../getcharity.service';
import { charitydata } from '../classes/charitydata';
import { toArray } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Component({
  selector: 'charity',
  templateUrl: './charity.component.html',
  styleUrls: ['./charity.component.scss']
})
export class CharityComponent implements OnInit {

  public details  = [];
  private charityObservable : Observable<charitydata>;
public det : any = [];

  constructor(private getcharity: GetcharityService) {
   
   
   }

  ngOnInit() {
    
    this.getcharity.charitydata()
     .subscribe((res ) => {
    console.log(res);
    this.details = res
    
    console.log(this.details);
   // this.det = Array.of(this.details);
   // console.log(this.det);   
}); 
    
}        
         
      
}



