export class addcharity{
    constructor(
    public name: string,
     public email: string,
    public mobile: string,
    public address_line1: string,
    public address_line2: string,
    public city: string,
    public country_id: number,
    public state_id: number,
    public zip: number,
    public registration_number: string,
    public charity_manager: string,
    public charity_manager_contact: string,
    public fundraiser_name: string,
    public fundraiser_contact: string
    ){}
}
