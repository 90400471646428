import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json;charset=utf-8',
    'Cache-Control': 'no-cache' ,   
   })
};
//const headers = 'headers made with HttpHeaders';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private logIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private router:Router) { }

  private TokenAPI = 'https://app.pluckyadmin.com/api/v1/auth/signin';

login(Username,Password) {
 
  var data = { username: Username , password: Password };
  var headers = {  'Content-Type':  'application/json;charset=utf-8'};
 return this.http.post(this.TokenAPI, data, {headers: headers ,observe:"response"});
 
} 

loggedIn() {
  this.logIn.next(true);
  return !!localStorage.getItem('token')
}

logoutUser() {
  this.logIn.next(false);
  localStorage.removeItem('token')
  this.router.navigate(['/login'])

}

get isLoggedIn() {
  return this.logIn.asObservable();
}


}
