import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { TokenParams } from '../classes/TokenParams';
import { HttpErrorResponse,  HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GetcharityService } from '../getcharity.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';




const httpoption = {
  headers: new HttpHeaders({
    'X-Auth-Token':  'auth-token',
     
  

  })
};


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  private formSubmitAttempt: boolean;
 

  loginUserData = { };
  tokenparams: TokenParams;
  username: string;
  password: string;
  isLoginError : boolean = false;
  public detail  = [];
  public dets : any = [];

  constructor(private authservice: AuthService,private http: HttpClient ,private fb: FormBuilder, private router: Router, private getcharity: GetcharityService) { }

  ngOnInit() {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  private charityurl = 'https://app.pluckyadmin.com/api/v1';

  dologin(userName ,passWord) {
    console.log(userName);
    console.log(passWord);
    this.authservice.login(userName,passWord).subscribe( (response: HttpResponse<string> )=> {
      localStorage.setItem('token',response.headers.get('X-Auth-Token'));
      let auth_token = localStorage.getItem('token')

     
     
      this.getcharity.charitydata()
      .subscribe((res ) => {
     console.log(res);
     this.detail = res
     
    // console.log(this.detail);
    // this.dets = Array.of(this.detail);
     //console.log(this.dets);
      this.router.navigate(['/charity']);
      });
              
    },
    (err : HttpErrorResponse)=>{
      this.isLoginError = true;
    });
  
  }

}
