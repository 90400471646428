import { NgModule } from '@angular/core';
import {Routes, RouterModule } from  '@angular/router';
import { CharityComponent } from './charity/charity.component';
import { SessionComponent } from './session/session.component';
import { NotificationComponent } from './notification/notification.component';
import { UserComponent } from './user/user.component';
import { AdminComponent } from './admin/admin.component';
import { AddCharityComponent } from './add-charity/add-charity.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { CharityListComponent } from './charity-list/charity-list.component';
import { AuthGuard } from './auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { HeaderComponent } from './header/header.component';


const routes: Routes = [
{ path: '', redirectTo: '/login', pathMatch: 'full'},   
{ path: 'login', component: LoginComponent},
{ path: 'logout', component: LogoutComponent},
{ path: 'header', component: HeaderComponent },
{ path: 'charity', component: CharityComponent, canActivate: [AuthGuard]},
{ path: 'session', component: SessionComponent, canActivate: [AuthGuard]},
{ path: 'user', component: UserComponent, canActivate: [AuthGuard]},
{ path:'admin', component: AdminComponent, canActivate: [AuthGuard]},
{ path: 'notification' , component: NotificationComponent, canActivate: [AuthGuard]},
{ path: 'add-charity' , component: AddCharityComponent, canActivate: [AuthGuard]},
{ path: 'charity-list', component: CharityListComponent, canActivate: [AuthGuard]},
{ path: '**', component: PageNotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [CharityComponent, SessionComponent, NotificationComponent, 
    UserComponent, AdminComponent, AddCharityComponent, LoginComponent, PageNotFoundComponent]