import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import {AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';



import { AddCharityComponent } from './add-charity/add-charity.component';
import { LoginComponent } from './login/login.component';
import { TransferFundComponent } from './transfer-fund/transfer-fund.component';

import { PackageComponent } from './package/package.component';
import { UserComponent } from './user/user.component';
import { AdminComponent } from './admin/admin.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { GetcharityService } from './getcharity.service';
import { CharityListComponent } from './charity-list/charity-list.component';
import { AuthGuard } from './auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { HeaderComponent } from './header/header.component';


@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    AddCharityComponent,
    LoginComponent,
    TransferFundComponent,
   
    PackageComponent,
   
    UserComponent,
   
    AdminComponent,
   
    PageNotFoundComponent,
   
    CharityListComponent,
   
    LogoutComponent,
   
    HeaderComponent,
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    AppRoutingModule,
  HttpClientModule 
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [AuthService, AuthGuard, GetcharityService],
  bootstrap: [AppComponent]
})
export class AppModule { }
